interface Window {
    jQuery: JQueryStatic
}

(function () {


    // Localize jQuery variable
    var jQuery: JQueryStatic;

    /******** Load jQuery if not present *********/
    if (window.jQuery === undefined || window.jQuery.fn.jquery !== '3.3.1') {
        var scriptTag = document.createElement('script');
        scriptTag.setAttribute("type", "text/javascript");
        scriptTag.setAttribute("src", "https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js");
        scriptTag.onload = scriptLoadHandler;

        // Try to find the head, otherwise default to the documentElement
        (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(scriptTag);
    } else {
        // The jQuery version on the window is the one we want to use
        jQuery = window.jQuery;
        main();
    }

    /******** Called once jQuery has loaded ******/
    function scriptLoadHandler() {
        // Restore $ and window.jQuery to their previous values and store the
        // new jQuery in our local jQuery variable
        jQuery = window.jQuery.noConflict(true);
        // Call our main function
        main();
    }

    /******** Our main function ********/
    function main() {
        jQuery(document).ready(function ($) {
            var siteRoot = 'https://localhost:7174',
                siteHome = 'localhost';

            /******* Load CSS *******/
            var cssLink = $("<link>", {
                rel: "stylesheet",
                type: "text/css",
                href: siteRoot + "/css/searchwidget.css"
            });
            cssLink.appendTo('head');

            var $widgetDiv = $('#fp-search-widget');

            var lang = $widgetDiv.data('lang') || 'en';
            var labels = {
                'en': {
                    'fpsw-heading': 'Search for funding',
                    'purpose': {},
                    'county': {},
                    'budget': {},
                    'clear_filters': 'Clear Filters',
                    'search': 'Find funding',
                    'select_all': 'Select all',
                    'confirm': 'Confirm',
                    'clear': 'Clear',
                    'additional': 'View additional options +'
                },
                'cy': {
                    'fpsw-heading': 'Chwilio am gyllid',
                    'purpose': {},
                    'county': {},
                    'budget': {},
                    'clear_filters': 'Clirio\'r Hidlyddion',
                    'search': 'Canfod cyllid',
                    'select_all': 'Dewis Pob un',
                    'confirm': 'Cadarnhau',
                    'clear': 'Clirio',
                    'additional': 'Gweld opsiynau ychwanegol +'
                }
            };

            $.get(siteRoot + "/searchwidget/labels", function (data) {
                labels['en']['purpose'] = data.en.fields.purpose;
                labels['en']['county'] = data.en.fields.county;
                labels['en']['budget'] = data.en.fields.budget;
                labels['en']['org_type'] = data.en.fields.org_type;
                labels['en']['cost_type'] = data.en.fields.cost_type;
                labels['en']['fund_type'] = data.en.fields.fund_type;
                labels['en']['fund_source'] = data.en.fields.fund_source;
                labels['en']['open_funds'] = data.en.fields.open_funds;
                labels['en']['living_wage_funds'] = data.en.fields.living_wage_funds;
                labels['en']['fund_funder_name'] = data.en.fields.fund_funder_name;



                labels['cy']['purpose'] = data.cy.fields.purpose;
                labels['cy']['county'] = data.cy.fields.county;
                labels['cy']['budget'] = data.cy.fields.budget;
                labels['cy']['org_type'] = data.cy.fields.org_type;
                labels['cy']['cost_type'] = data.cy.fields.cost_type;
                labels['cy']['fund_type'] = data.cy.fields.fund_type;
                labels['cy']['fund_source'] = data.cy.fields.fund_source;
                labels['cy']['open_funds'] = data.cy.fields.open_funds;
                labels['cy']['living_wage_funds'] = data.cy.fields.living_wage_funds;
                labels['cy']['fund_funder_name'] = data.cy.fields.fund_funder_name;

                setupWidget();
            });


            function setupWidget() {

                $widgetDiv
                    .addClass('fpsw')
                    .append('<div id="fpsw-form-container" class="fpsw-form-container"></div>');


                var $html = $('<div></div>');
                $html.append('<div class="fpsw-header"><h2 class="fpsw-heading">' + labels[lang]['fpsw-heading'] + '</h2></div>');

                $html.append(renderFormHtml());


                $('#fpsw-form-container').html($html as any);


                /******* Setup events *******/

                $('body').on('click', '.fpsw-form-clear', function (e) {
                    e.preventDefault;
                    $('.custom-select__clear').trigger('click');
                    $('#fpsw-form').find('select').val('').trigger('change');
                });

                // Custom select with popover events
                $('.custom-select').click(function () {
                    $(this).next('.custom-select__window').toggleClass('open');
                });

                $('body').on('click', '.custom-select__confirm', function (e) {
                    e.preventDefault();
                    $(this).closest('.custom-select__window').removeClass('open');
                    $(this).closest('.custom-select__text').text($(this).text);
                });


                $('body').on('change', '.custom-select__wrapper .custom-checkbox', function (e) {
                    var $el = $(this),
                        $customSelect = $el.closest('.custom-select__wrapper'),
                        $checkboxes = $customSelect.find('input[type="checkbox"]:checked'),
                        selectedText = $customSelect.find('.custom-select').data('custom-select-placeholder') || '';

                    if ($checkboxes.length) {
                        var selection = [];
                        $checkboxes.each(function () {
                            selection.push($(this).next('label').text());
                        })
                        selectedText = selection.join(', ');
                    }
                    $customSelect.find('.custom-select__text').text(selectedText)
                });

                $('.custom-select__all').click(function (e) {
                    e.preventDefault();
                    $(this).closest('.custom-select__window')
                        .find('.custom-select__options input[type="checkbox"]')
                        .prop('checked', true).trigger('change');
                });

                $('.custom-select__clear').click(function (e) {
                    e.preventDefault();
                    $(this).closest('.custom-select__window')
                        .find('.custom-select__options input[type="checkbox"]')
                        .prop('checked', false).trigger('change');
                });

                $('body').on('click', function (e) {
                    if (!$(e.target).closest('.custom-select').length && !$(e.target).closest('.custom-select__window').length) {
                        $('.custom-select__window').removeClass('open');
                    }
                    else {
                        //debugger;
                        //e.preventDefault();
                        //custom-control-input
                    }
                });




                /******* Populate search parameters from url *******/
                const urlParams = new URLSearchParams(window.location.search);
                var categories = urlParams.getAll('category');
                var counties = urlParams.getAll('county');
                var budget = urlParams.get('budget');

                var org_type = urlParams.get('org_type');
                var cost_type = urlParams.get('cost_type');
                var fund_type = urlParams.get('fund_type');
                var fund_source = urlParams.get('fund_source');

                var fund_funder_name = urlParams.get('fund_funder_name');

                var livingwage_only = urlParams.get('livingwage_only');
                var open_only = urlParams.get('open_only');



                var event = new Event('change', { bubbles: true });
                for (const category of categories) {
                    let c = document.getElementById(`category_${category}`) as HTMLInputElement;
                    if (c) {
                        c.checked = true;
                        c.parentElement.dispatchEvent(event);
                    }
                }

                for (const county of counties) {
                    let c = document.getElementById(`county_${county}`) as HTMLInputElement;
                    if (c) {
                        c.checked = true;
                        c.parentElement.dispatchEvent(event);
                    }
                }

                let budget_control = document.querySelector('select[name="budget"]') as HTMLSelectElement;
                if (budget)
                    budget_control.value = budget;

                let org_type_control = document.querySelector('select[name="org_type"]') as HTMLSelectElement;
                if (org_type)
                    org_type_control.value = org_type;

                let cost_type_control = document.querySelector('select[name="cost_type"]') as HTMLSelectElement;
                if (cost_type)
                    cost_type_control.value = cost_type;

                let fund_type_control = document.querySelector('select[name="fund_type"]') as HTMLSelectElement;
                if (fund_type)
                    fund_type_control.value = fund_type;

                let fund_source_control = document.querySelector('select[name="fund_source"]') as HTMLSelectElement;
                if (fund_source)
                    fund_source_control.value = fund_source;

                let fund_funder_name_control = document.querySelector('input[name="fund_funder_name"]') as HTMLInputElement;
                fund_funder_name_control.value = fund_funder_name;


                let livingwage_only_control = document.querySelector('input[name="livingwage_only"]') as HTMLInputElement;
                if (livingwage_only)
                    livingwage_only_control.checked = true;

                let open_only_control = document.querySelector('input[name="open_only"]') as HTMLInputElement;
                if (open_only)
                    open_only_control.checked = true;

            }

            /******** Render Form HTML ********/
            function renderFormHtml() {


                /*var action = lang != 'en' ? 'http://' + lang + '.' + siteHome + '/search' : siteRoot + '/search/';*/
                var action = siteRoot + '/' + lang + '/search';

                // Form Wrapper
                var $form = $('<form id="fpsw-form" class="fpsw-form" method="GET" action="' + action + '"></form>');

                // Form Inputs
                var $inputHtml = $('<div class="fpsw-form-fields"></div>');

                var $inputs = [];
                $inputs.push(renderFormMultiSelect('category', labels[lang]['purpose']['label'], labels[lang]['purpose']['options'], labels[lang]['purpose']['placeholder']));

                $inputs.push(renderFormMultiSelect('county', labels[lang]['county']['label'], labels[lang]['county']['options'], labels[lang]['county']['placeholder']));

                $inputs.push(renderFormSelect('budget', labels[lang]['budget']['label'], labels[lang]['budget']['options'], labels[lang]['budget']['placeholder']));

                $inputs.push(renderFormField('submit', '', labels[lang]['search'], '', 'fpsw-btn fpsw-btn--success', ' '));


                $.each($inputs, function () {
                    $inputHtml.append($(this));
                });

                $form.append($('<div class="fpsw-form-group"></div>').append($inputHtml));




                $form.append($('<div class="arrow_container"><a href="" class="form-sublabel link-color--success font-weight-normal text-nowrap" data-toggle-open="#advanced"><div class="chevron" > </div><div class="chevron"></div><div class= "chevron"></div><span class= "text">' + labels[lang]['additional'] + '</span></a></div>'));



                var $advancedinputHtml = $('<div class="fpsw-form-fields"></div>');

                var $advancedinputs = [];



                $advancedinputs.push(renderFormSelect('org_type', labels[lang]['org_type']['label'], labels[lang]['org_type']['options'], labels[lang]['org_type']['placeholder']));

                $advancedinputs.push(renderFormSelect('cost_type', labels[lang]['cost_type']['label'], labels[lang]['cost_type']['options'], labels[lang]['cost_type']['placeholder']));

                $advancedinputs.push(renderFormField('text', 'fund_funder_name', '', labels[lang]['fund_funder_name']['placeholder'], '', labels[lang]['fund_type']['label']));

                $advancedinputs.push($('<div class="fpsw-form-input"></div>'));




                $advancedinputs.push(renderFormSelect('fund_type', labels[lang]['fund_type']['label'], labels[lang]['fund_type']['options'], labels[lang]['fund_type']['placeholder']));

                $advancedinputs.push(renderFormSelect('fund_source', labels[lang]['fund_source']['label'], labels[lang]['fund_source']['options'], labels[lang]['fund_source']['placeholder']));


                var $advancedinputCheckboxGroupHtml = $('<div class="fpsw-form-input" style="margin-top: 10px !important; margin-bottom: 0px!important;"></div>');
                $advancedinputCheckboxGroupHtml.append(renderFormField('checkbox', 'open_only', 'true', '', '', labels[lang]['open_funds']['label']));
                $advancedinputCheckboxGroupHtml.append(renderFormField('checkbox', 'livingwage_only', 'true', '', '', labels[lang]['living_wage_funds']['label']));
                $advancedinputs.push($advancedinputCheckboxGroupHtml);

                $advancedinputs.push($('<div class="fpsw-form-input"></div>'));

                //$advancedinputs.push(renderFormField('checkbox', 'open_funds', '', '', '', labels[lang]['open_funds']['label']));
                //$advancedinputs.push(renderFormField('checkbox', 'living_wage_funds', '', '', '', labels[lang]['living_wage_funds']['label']));

                //$advancedinputs.push(renderFormField('hidden', 'utm_source', 'funding_wales_widget'));
                //$advancedinputs.push(renderFormField('hidden', 'utm_medium', 'embed'));

                $.each($advancedinputs, function () {
                    $advancedinputHtml.append($(this));
                });

                $form.append($('<div id="advanced" class="fpsw-form-group advanced"></div>').append($advancedinputHtml));


                $form.find('[data-toggle-open]')
                    .first()
                    .click(function (e) {
                        e.preventDefault();
                        $($(this).data('toggle-open')).toggleClass('open');
                    });

                return $form;
            }




            /******** Render Form Input ********/
            function renderFormField(type, name, value, placeholder?, inputClass?, label?) {


                if (type == "checkbox") {

                    var $input = $('<div class=""></div>');

                    var $control = $('<div class="custom-control custom-checkbox"></div>');

                    $control.append($('<input class="custom-control-input ' + (inputClass || "") + '" type="' + type + '" name="' + name + '" id="fpsw-form-' + name + '" placeholder="' + (placeholder || "") + '" value="' + (value || "") + '"' + (type == 'number' ? " min='1' max='50'" : "") + '/>'));
                    $control.append('<label class="custom-control-label" for="fpsw-form-' + name + '">' + label + '</label>');


                    $input.append($control);

                    return $input;
                }
                else {

                    var $input = $('<div class="fpsw-form-input"></div>');
                    if (label) {
                        $input.append($('<label for="fpsw-form-' + name + '">' + label + '</label>'));
                    }

                    $input.append($('<input class="' + (inputClass || "") + '" type="' + type + '" name="' + name + '" id="fpsw-form-' + name + '" placeholder="' + (placeholder || "") + '" value="' + (value || "") + '"' + (type == 'number' ? " min='1' max='50'" : "") + '/>'));

                    return $input;
                }

            }

            /******** Render Form Custom Select ********/
            function renderFormMultiSelect(name, label, options, placeholder) {

                var item;
                var $input = $('<div class="fpsw-form-input fpsw-form-input--select"></div>');

                $input.append($('<label for="fpsw-form-' + name + '">' + label + '</label>'));

                var $select = $('<div class="custom-select__wrapper"></div>');

                $select.append('<div class="form-control form-control-lg custom-select" data-custom-select-placeholder="' + placeholder + '"><span class="custom-select__text">' + placeholder + '</span><span class="select-arrow"></span></div>');

                var $window = $('<div class="custom-select__window"></div>');

                var $options = $('<div class="custom-select__options mb-3"></div>');

                Object.keys(options).forEach(function (key) {
                    item = options[key];

                    if (name == "category")
                        $options.append($('<div class="custom-control custom-checkbox"><input id="category_' + item.id + '" class="custom-control-input" value="' + item.id + '" name="category" type="checkbox"><label for="category_' + item.id + '" class="custom-control-label">' + item.title + '</label></div>'));

                    if (name == "county")
                        $options.append($('<div class="custom-control custom-checkbox"><input id="county_' + item.id + '" class="custom-control-input" value="' + item.id + '" name="county" type="checkbox"><label for="county_' + item.id + '" class="custom-control-label">' + item.title + '</label></div>'));
                });

                $window.append($options);

                $window.append('<div class="d-flex flex-wrap justify-content-between"><button class="fpsw-btn fpsw-btn--outline fpsw-btn--trans custom-select__all mb-2">' + labels[lang]["select_all"] + '</button><button class="fpsw-btn fpsw-btn--primary custom-select__confirm ml-2">' + labels[lang]["confirm"] + '</button></div>');

                $window.append('<a href="" class="pt-3 pt-1 d-block text-center w-100 link-color--primary custom-select__clear text-uppercase font-weight-semibold"><small>' + labels[lang]["clear"] + '</small></a>');

                $select.append($window);

                $input.append($select);

                return $input;
            }

            /******** Render Form Select ********/
            function renderFormSelect(name, label, options, placeholder, preselectkey?) {
                var selected, item;
                var $input = $('<div class="fpsw-form-input fpsw-form-input--select"></div>');

                $input.append($('<label for="fpsw-form-' + name + '">' + label + '</label>'));

                var $select = $('<select name="' + name + '"></select>');

                $select.append($('<option value="" selected="selected">' + placeholder + '</option>'));

                preselectkey = preselectkey ?? "";
                Object.keys(options).forEach(function (key) {
                    item = options[key];
                    selected = key === preselectkey ? 'selected="selected"' : '';
                    $select.append($('<option value="' + item.id + '"' + selected + '>' + item.title + '</option>'));
                });

                $input.append($select);

                return $input;
            }
        });
    }

})();
